const AppReducer = (state, action) => {
  switch (action.type) {
    case 'LOGOUT':
      return action.payload
    case 'AUTH':
      return { ...state, ...action.payload }
    case 'LOADING':
      return { ...state, loading: action.payload }
    default:
      return state
  }
}

export default AppReducer