import React from 'react'
import { Outlet } from 'react-router-dom'

import Footer from '../shared/Footer';
import logo from '../../../images/logo.png'

const GuestLayout = () => {
  return (
    <div id="layoutAuthentication" className="bg-white">
      <div id="layoutAuthentication_content">
        <main>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 mt-4 text-center">
                <img src={logo} alt="ABC da Construção" height="150" />
              </div>
            </div>
            <div className="row justify-content-center">
              <Outlet />
            </div>
          </div>
        </main>
      </div>
      <div id="layoutAuthentication_footer">
        <Footer/>
      </div>
    </div>
  )
}

export default GuestLayout