import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { localStorageManager } from '../../../services/LocalStorageService'
import { setLogout } from '../../../store/AppActions'
import { AppContext } from '../../../store/AppStore'

const TopNav = () => {
  const {appDispatch} = useContext(AppContext)
  const navigate = useNavigate();
  const url = ''

  const logout = (event) => {
    event.preventDefault()
    localStorageManager.clear()
    setLogout(appDispatch)
    navigate('/login')
  }

  return (
    <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
      <Link to="/admin/dashboard" className="navbar-brand ps-3">SAG - ABC</Link>

      <button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle">
        <i className="fas fa-bars"></i>
      </button>

      <ul className="navbar-nav ms-auto me-3 me-lg-4">
        <li className="nav-item dropdown">
          <a href={url} className="nav-link dropdown-toggle" id="navbarDropdown" role="button"
             data-bs-toggle="dropdown" aria-expanded="false">
            <i className="fas fa-user fa-fw"></i>
          </a>
          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
            <li>
              <Link to="/admin/auth/meus-dados" className="dropdown-item">
                <i className="fa-solid fa-id-badge fa-lg"></i> Meus dados
              </Link>
            </li>
            <li>
              <hr className="dropdown-divider"/>
            </li>
            <li>
              <a href={url} className="dropdown-item" onClick={(event) => logout(event)}>
                <i className="fa-solid fa-right-from-bracket fa-lg text-danger"></i> Sair
              </a>
            </li>
            <li>
              <Link to="/admin/logout" className="dropdown-item">
                <i className="fa-solid fa-right-from-bracket fa-lg text-danger"></i> Sair em todos os dispositivos
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  )
}

export default TopNav