import React, { useEffect, useReducer } from 'react'

import AppReducer from './AppReducer'
import { setAuth } from './AppActions'
import { storageKeys, localStorageManager } from '../services/LocalStorageService'

export const appContextInitialState = {
  auth: {},
  isRoot: false,
  isAdmin: false,
  loading: false
}

export const AppContext = React.createContext(appContextInitialState)

const AppStore = props => {
  const [appState, appDispatch] = useReducer(AppReducer, appContextInitialState)

  useEffect(() => {
    if (!appState.auth.id && localStorageManager.has(storageKeys.auth)) {
      const auth = localStorageManager.get(storageKeys.auth)

      setAuth(appDispatch, {auth})
    }
  }, [appState])

  return (
    <AppContext.Provider value={{appState, appDispatch}}>
      {props.children}
    </AppContext.Provider>
  )
}

export default AppStore