import React from 'react'
import { Navigate } from 'react-router-dom'
import { localStorageManager, storageKeys } from '../services/LocalStorageService'

const Index = () => {
  if (!localStorageManager.has(storageKeys.data)) {
    return <Navigate to="/login"/>
  }

  return <Navigate to="/admin/apps"/>
}

export default Index