import { appContextInitialState } from './AppStore'

export const setLogout = (dispatch) => {
  dispatch({type: 'LOGOUT', payload: appContextInitialState})
}

export const setAuth = (dispatch, payload) => {
  let apps = []

  if (payload.auth && payload.auth.apps.length) {
    apps = payload.auth.apps.filter(app => app.super_admin === 1)
  }

  payload = {
    ...payload,
    isRoot: payload.auth.root === 1,
    isAdmin: apps.length > 0
  }

  dispatch({type: 'AUTH', payload})
}

export const setLoading = (dispatch, payload) => {
  dispatch({type: 'LOADING', payload})
}