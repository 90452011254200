import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { AppContext } from '../../../store/AppStore'

const SideNav = () => {
  const {appState} = useContext(AppContext)

  return (
    <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
      <div className="sb-sidenav-menu">
        <div className="nav">
          <div className="sb-sidenav-menu-heading">Painel Administrativo</div>
          <NavLink to="/admin/apps" className="nav-link">
            <div className="sb-nav-link-icon"><i className="fa-solid fa-w fa-laptop-code"></i></div>
            Aplicações
          </NavLink>
          {
            !appState.isRoot ? null :
            <NavLink to="/admin/users" className="nav-link">
              <div className="sb-nav-link-icon"><i className="fa-solid fa-w fa-users"></i></div>
              Usuários
            </NavLink>
          }
        </div>
      </div>
      <div className="sb-sidenav-footer">
        <div className="small">Logado como:</div>
        {appState.auth?.name}
      </div>
    </nav>
  )
}

export default SideNav
