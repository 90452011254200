import React, { lazy, Suspense, useContext } from 'react'
import { Routes, Route } from 'react-router-dom'

import { AppContext } from './store/AppStore'

import Index from './pages/Index';
import PainelAdmin from './components/template/sb-admin/PainelAdmin'
import GuestLayout from './components/template/auth/GuestLayout'
import Login from './pages/Login'

const PasswordRecover = lazy(() => import ('./pages/PasswordRecover'))
const PasswordCreate = lazy(() => import ('./pages/PasswordCreate'))
const Unauthorized = lazy(() => import ('./pages/Unauthorized'))
const NotFound = lazy(() => import ('./pages/NotFound'))

// Rotas admin
const Profile = lazy(() => import ('./pages/admin/auth/Profile'))
const Logout = lazy(() => import ('./pages/admin/auth/Logout'))
const NotFoundAdmin = lazy(() => import ('./pages/admin/NotFoundAdmin'))
const UsersIndex = lazy(() => import ('./pages/admin/users/UsersIndex'))
const UserCreate = lazy(() => import ('./pages/admin/users/UserCreate'))
const UserEdit = lazy(() => import ('./pages/admin/users/UserEdit'))
const AppsIndex = lazy(() => import ('./pages/admin/apps/AppsIndex'))
const AppCreate = lazy(() => import ('./pages/admin/apps/AppCreate'))
const AppManager = lazy(() => import ('./pages/admin/apps/AppManager'))
const AppGroupManager = lazy(() => import ('./pages/admin/apps/AppGroupManager'))

const AppRoutes = () => {
  const {appState} = useContext(AppContext)

  return (
    <Routes>
      <Route path="/" element={<Index/>}/>

      {/* Rotas guest */}
      <Route element={<GuestLayout/>}>
        <Route path="/login" element={<Login/>}/>
        <Route path="/senha/criar"
               element={<Suspense fallback={<>Loading...</>}><PasswordCreate/></Suspense>}/>
        <Route path="/senha/recuperar"
               element={<Suspense fallback={<>Loading...</>}><PasswordRecover/></Suspense>}/>
        <Route path="/sem-permissao"
               element={<Suspense fallback={<>Loading...</>}><Unauthorized/></Suspense>}/>
      </Route>

      {/* Rotas admin */}
      <Route path="/admin" element={<PainelAdmin/>}>
        <Route index element={<Suspense fallback={<>Loading...</>}><AppsIndex/></Suspense>}/>
        <Route path="auth/meus-dados"
               element={<Suspense fallback={<>Loading...</>}><Profile/></Suspense>}/>
        <Route path="logout" element={<Suspense fallback={<>Loading...</>}><Logout/></Suspense>}/>

        {/* Aplicações */}
        <Route path="apps" element={<Suspense fallback={<>Loading...</>}><AppsIndex/></Suspense>}/>
        <Route path="apps/criar" element={<Suspense fallback={<>Loading...</>}><AppCreate/></Suspense>}/>
        <Route path="apps/:appName/gerenciar"
               element={<Suspense fallback={<>Loading...</>}><AppManager/></Suspense>}/>
        <Route path="apps/:appName/grupos/:groupId"
               element={<Suspense fallback={<>Loading...</>}><AppGroupManager/></Suspense>}/>

        {/* Usuários */}
        {
          !appState.isRoot ? null :
            <>
              <Route path="users/criar"
                     element={<Suspense fallback={<>Loading...</>}><UserCreate/></Suspense>}/>
              <Route path="users/:id/editar"
                     element={<Suspense fallback={<>Loading...</>}><UserEdit/></Suspense>}/>
              <Route path="users"
                     element={<Suspense fallback={<>Loading...</>}><UsersIndex/></Suspense>}/>
            </>
        }

        {/* Notfound Painel Admin */}
        <Route path="*" element={<Suspense fallback={<>Loading...</>}><NotFoundAdmin/></Suspense>}/>
      </Route>

      {/* Notfound */}
      <Route path="*" element={<Suspense fallback={<>Loading...</>}><NotFound/></Suspense>}/>
    </Routes>
  )
}

export default AppRoutes