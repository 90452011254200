export const storageKeys = {
  data: 'data',
  auth: 'auth'
}

export const localStorageManager = {
  has: key => {
    return !!window.localStorage.getItem(key)
  },

  set: (key, value) => {
    value = JSON.stringify(value)
    value = btoa(value)
    window.localStorage.setItem(key, value)
  },

  get: key => {
    let value = window.localStorage.getItem(key)

    if (value) {
      value = atob(value)
      value = JSON.parse(value)
    }

    return value
  },

  remove: keys => {
    if (Array.isArray(keys)) {
      keys.forEach(key => window.localStorage.removeItem(key))
    } else {
      window.localStorage.removeItem(keys)
    }
  },

  clear: () => {
    window.localStorage.clear()
  }
}
