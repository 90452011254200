import ApiHttp from '../plugins/ApiHttp'
import { storageKeys, localStorageManager } from './LocalStorageService'

export const hasToken = () => {
  return localStorageManager.has(storageKeys.data)
}

export const saveToStorage = (data) => {
  localStorageManager.set(storageKeys.data, data)

  /*let auth = data.token.split('.')[1]
  auth = JSON.parse(atob(auth))*/
  let auth = JSON.parse(atob(data.payload))

  localStorageManager.set(storageKeys.auth, auth)

  return auth
}

export const login = data => {
  return ApiHttp.post('/auth/login', data)
}

export const logout = () => {
  return ApiHttp.post('/auth/logout', {})
}

export const refreshToken = () => {
  return ApiHttp.post('/auth/refresh')
}

export const passwordCreate = data => {
  return ApiHttp.post('/auth/password/create', data)
}

export const postPasswordRecover = data => {
  return ApiHttp.post('/auth/password/recover', data)
}

export const putPasswordRecover = data => {
  return ApiHttp.put('/auth/password/recover', data)
}

export const getProfile = () => {
  return ApiHttp.get('/auth/profile')
}

export const putProfile = data => {
  return ApiHttp.put('/auth/profile', data)
}

export const putPasswordUpdate = data => {
  return ApiHttp.put('/auth/password/update', data)
}