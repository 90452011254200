import React from 'react'

import moment from 'moment'
import { app } from '../../../globals'

const Footer = () => {
  const anoAtual = moment().format('YYYY')

  return (
    <footer className="py-4 bg-light mt-auto">
      <div className="container-fluid px-4">
        <div className="d-flex align-items-center justify-content-between small">
          <div className="text-muted">Copyright &copy; abcdaconstrucao.com.br {anoAtual}</div>
          <div>
            Versão: {app.version}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer