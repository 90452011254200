import axios from 'axios'
import qs from 'qs'

import { api } from '../globals'
import { storageKeys, localStorageManager } from '../services/LocalStorageService'
import { refreshToken, saveToStorage } from '../services/AuthService'
import EventEmitter from './EventEmitter'

const ApiHttp = axios.create({
  baseURL: api.url
})

const apiUrlExceptToken = [
  '/auth/login',
  '/auth/password/create',
  '/auth/password/recover',
]

// intercepta a requisição antes de sair (middleware Before)
ApiHttp.interceptors.request.use(
  request => {
    if (request.method === 'post' || request.method === 'put') {
      request.data = qs.stringify(request.data)
    }

    if (!apiUrlExceptToken.includes(request.url) && localStorageManager.has(storageKeys.data)) {
      const localData = localStorageManager.get(storageKeys.data)
      request.headers.Authorization = `${localData.token_tipo} ${localData.token}`
    }

    return request
  },

  error => Promise.reject(error)
)

// intercepta a resposta (middleware After)
ApiHttp.interceptors.response.use(
  response => response,

  async error => {

    const originalRequest = error.config

    if (error.response.status === 401 && originalRequest.url === '/auth/refresh') {
      EventEmitter.emit('unauthenticated')

      return Promise.reject(error)
    }

    if (error.response.status === 401 && originalRequest.url !== '/auth/login') {
      return await refreshToken()
        .then(res => {
          saveToStorage(res.data)
          originalRequest.headers.Authorization = `${res.data.token_tipo} ${res.data.token}`

          return ApiHttp(originalRequest)
        })
        .catch(() => {
          EventEmitter.emit('unauthenticated')

          return Promise.reject(error)
        })
    }

    return Promise.reject(error)
  }
)

export default ApiHttp