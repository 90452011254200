import React, { useContext, useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { login, saveToStorage } from '../services/AuthService'
import { localStorageManager, storageKeys } from '../services/LocalStorageService'
import { AppContext } from '../store/AppStore';
import { setAuth, setLogout } from '../store/AppActions'

const Login = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({username: '', password: ''})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({})
  const {appDispatch} = useContext(AppContext)

  const handleChange = event => {
    const newForm = {...form}
    newForm[event.target.name] = event.target.value;
    setForm(newForm);
  }

  const handleSubmit = event => {
    event.preventDefault()
    setLoading(true)
    setError({})

    login({...form, password: btoa(form.password)})
      .then(response => {
        const auth = saveToStorage(response.data)
        setAuth(appDispatch, {auth})
        navigate('/admin/apps')
      })
      .catch(error => {
        localStorageManager.clear()
        setLogout(appDispatch)
        if (error.response) {
          setError(error.response.data)
        }
        setLoading(false)
      })
  }

  if (localStorageManager.has(storageKeys.data)) {
    return <Navigate to="/admin/apps"/>
  }

  return (
    <div className="col-lg-5">
      <div className="card shadow-lg border-0 rounded-lg mt-5">
        <div className="card-header">
          <h3 className="text-center font-weight-light my-4">Login</h3>
        </div>
        <div className="card-body">
          <p className="text-danger">{error.message}</p>
          <form onSubmit={handleSubmit}>
            <div className="form-floating mb-3">
              <input className={`form-control ${error.errors?.username ? 'is-invalid' : ''}`}
                     id="inputEmail" type="text" name="username"
                     onChange={handleChange}/>
              <label htmlFor="inputEmail"><i className="fa-solid fa-user"></i> Usuário</label>
              <div className="invalid-feedback">
                {error.errors?.username ? error.errors.username[0] : null}
              </div>
            </div>
            <div className="form-floating mb-3">
              <input className={`form-control ${error.errors?.password ? 'is-invalid' : ''}`}
                     id="inputPassword" type="password" name="password"
                     onChange={handleChange}/>
              <label htmlFor="inputPassword"><i className="fa-solid fa-key"></i> Senha</label>
              <div className="invalid-feedback">
                {error.errors?.password ? error.errors.password[0] : null}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-end mt-4 mb-0">
              <button type="submit" className="btn btn-primary" disabled={loading}>Login</button>
            </div>
          </form>
        </div>
        <div className="card-footer text-center py-3">
          <div className="small">
            <Link to="/senha/recuperar">Recuperar minha senha</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login