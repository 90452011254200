import React, { useContext, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { AppContext } from '../../../store/AppStore'
import { setLogout } from '../../../store/AppActions'
import { localStorageManager, storageKeys } from '../../../services/LocalStorageService'
import EventEmitter from '../../../plugins/EventEmitter'

import TopNav from './TopNav'
import SideNav from './SideNav'
import Footer from '../shared/Footer'

const PainelAdmin = () => {
  const {appState, appDispatch} = useContext(AppContext)
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorageManager.has(storageKeys.data) || !localStorageManager.has(storageKeys.auth)) {
      navigate('/login')
    }

    const listener = EventEmitter.addListener('unauthenticated', unauthenticated)

    return () => listener.remove()
  })

  useEffect(() => {
    if (appState.isRoot === false && appState.isAdmin === false) {
      navigate('/sem-permissao')
    }
  }, [appState])

  const unauthenticated = () => {
    localStorageManager.clear()
    setLogout(appDispatch)
    navigate('/login')
  }

  return (
    <>
      <TopNav/>
      <div id="layoutSidenav">
        <div id="layoutSidenav_nav">
          <SideNav/>
        </div>
        <div id="layoutSidenav_content">
          <main>
            <div className="container-fluid px-3 py-1">
              <Outlet/>
            </div>
          </main>
          <Footer/>
        </div>
      </div>
    </>
  )
}

export default PainelAdmin